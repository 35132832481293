import { Box } from "@chakra-ui/react";
import { useAuth } from "@the-volte/svc-core-sdk";
import Image from "next/image";
import React from "react";

import { Button } from "@components/atoms";
import { H1 } from "@components/atoms/Text/H1";
import CustomLink from "@components/organisms/Home/styles";
import { HeroSectionMainProps } from "@components/pages/Home/types";
import { paths } from "@paths";

const HeroSectionMain: React.FC<HeroSectionMainProps> = ({
  handleWidthBasedOnHover,
  position,
  hoveredSection,
  setHoveredSection,
  imageSrc,
  ctaTitle,
  ctaButton,
  alignItems,
  onClickCtaAction,
  altTitleColor,
}) => {
  const { user } = useAuth();

  return (
    <Box
      width={handleWidthBasedOnHover(position, hoveredSection)}
      height="full"
      bgColor="#454545"
      overflow="hidden"
      position="relative"
      style={{ transition: "all 0.5s" }}
      onMouseEnter={() => setHoveredSection(position)}
    >
      <Image
        style={{ height: "100%" }}
        layout="responsive"
        src={imageSrc}
        alt={`${position} - hero`}
        priority
      />
      <Box
        color="black"
        display="flex"
        flexDirection="row"
        w="full"
        h="full"
        position="absolute"
        top="0"
        padding="1.875rem 1.875rem"
      >
        <Box
          w="full"
          display="flex"
          flexDirection="column"
          alignItems={alignItems}
        >
          <H1
            as="span"
            color={altTitleColor ? "#000" : "#fff"}
            fontSize="2.75rem"
            lineHeight="3rem"
          >
            {ctaTitle}
          </H1>

          {ctaButton.includes("Become a lender") ? (
            <CustomLink
              external
              href={
                !user
                  ? paths.login
                  : user?.defaultShippingAddress
                  ? paths.accountLending
                  : paths.accountAddressBook
              }
              style={{
                paddingLeft: "25px",
                paddingRight: "30px",
                marginTop: "20px",
                backgroundColor: "#ffffff66",
                color: "#fff",
                border: "none",
              }}
              hoverBackgroundColor="#ffffff66"
            >
              {ctaButton}
            </CustomLink>
          ) : (
            <Button
              size="md"
              fontSize="24px"
              pl="25px"
              pr="30px"
              mt="20px"
              variant="secondary"
              onClick={() => onClickCtaAction?.()}
              bgColor={altTitleColor ? "#8d8d8d66" : "#ffffff66"}
              border="none"
              color={altTitleColor ? "#000" : "#fff"}
              _hover={{ bg: "#ffffff66" }}
            >
              {ctaButton}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default HeroSectionMain;
