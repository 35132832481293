import { Box, Flex, Grid } from "@chakra-ui/react";
import React from "react";

import { RichTextEditorContent } from "@components/atoms";
import { ProductTile } from "@components/molecules";
import {
  AsSeenIn,
  CircularPartners,
  CollectionGrid,
  FeaturedDesigners,
  SeamlessLending,
} from "@components/organisms";
import HeroSectionMobile from "@components/organisms/Home/homepage-mobile/HeroSectionMobile";

import { HomePageProps } from "./types";

const HomePage: React.FC<HomePageProps> = ({ collection, collections }) => {
  const { description, products } = collection || {};

  return (
    <Flex flexDirection="column" overflow="hidden" alignItems="center">
      <HeroSectionMobile />
      {collections && <CollectionGrid collections={collections} />}
      <Grid
        w="100%"
        templateColumns={[
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          null,
          null,
          "repeat(3, 1fr)",
          "repeat(4, 1fr)",
        ]}
        gap={3}
        padding={["0.93rem", null, null, "3.75rem"]}
        rowGap={14}
      >
        {products?.edges?.map((product) => (
          <ProductTile product={product.node} key={product.node.id} isMobile />
        ))}
      </Grid>
      <Box width="100%">
        <FeaturedDesigners
          title="FEATURED DESIGNERS"
          ctaText="View all designers  →"
          content="Browse collections from The Volte's favourite designers and discover hot new brands we know you will love."
        />
      </Box>
      <SeamlessLending />
      <CircularPartners />
      <AsSeenIn />

      <Box padding="30px 15px 30px 15px" bgColor="secondary">
        <RichTextEditorContent jsonData={description} sanitizationNotRequired />
      </Box>
    </Flex>
  );
};

export default HomePage;
