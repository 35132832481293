import { useMediaQuery } from "@chakra-ui/react";
import { NextPage } from "next";
import { useEffect } from "react";

import {
  structuredOrganizationData,
  structuredWebsiteData,
} from "@app/core/SEO/Homepage/structuredData";
import HomePageDesktop from "@components/pages/Home/HomePageDesktop";
import { useLocalStorageQuiet } from "@hooks/useLocalStorage";
import { breakpoints } from "@styles/constants";

import { MetaWrapper } from "../../components/legacy";
import HomePage from "../../components/pages/Home/HomePage";
import { HomePageProducts } from "./gqlTypes/HomePageProducts";

export interface HomeViewProps {
  data: HomePageProducts;
}

export const HomeView: NextPage<HomeViewProps> = ({
  data: { shop, collection, collections, marketplaces },
}) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [, setScrollState] = useLocalStorageQuiet("scrollOffset", {});

  // Reset scroll positions for all product list pages when mounting home
  useEffect(() => {
    setScrollState({});
  });

  return (
    <>
      <script
        className="structured-data-list"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: structuredWebsiteData(shop),
        }}
      />
      <script
        className="structured-data-list"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: structuredOrganizationData(),
        }}
      />
      <MetaWrapper
        meta={{
          description: shop?.description || "",
          title: shop.name || "",
          image: collection?.products?.edges?.[0]?.node?.thumbnail2x?.url,
          language: shop?.language || undefined,
          canonicalUrl: shop?.domain?.url,
          marketplaces,
        }}
      >
        {isDesktop ? (
          <HomePageDesktop
            collection={collection}
            collections={collections}
            shop={shop}
          />
        ) : (
          <HomePage
            collection={collection}
            collections={collections}
            shop={shop}
          />
        )}
      </MetaWrapper>
    </>
  );
};
