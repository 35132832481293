import styled from "@emotion/styled";
import Link from "next/link";
import { AnchorHTMLAttributes } from "react";

import { media } from "@styles";

interface CustomLinkStyledProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  hoverBackgroundColor?: string;
}

const CustomLinkStyled = styled.a<CustomLinkStyledProps>`
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: pre;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: none;
  line-height: 13px;
  border-radius: 4.375rem;
  font-weight: var(--chakra-fontWeights-normal);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  border: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-black);
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  font-size: 24px;
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  padding: 1rem 1.25rem;

  &:hover {
    background-color: ${(props) =>
      props.hoverBackgroundColor ||
      "var(--chakra-colors-tertiaryLight)"} !important;
    color: var(--chakra-colors-primary);
    border: 0;
  }

  ${media.mediumScreen`
        height: var(--chakra-sizes-8);
        min-width: var(--chakra-sizes-8); 
        -webkit-padding-start: var(--chakra-space-3);
        padding-inline-start: var(--chakra-space-3);
        -webkit-padding-end: var(--chakra-space-3);
        padding-inline-end: var(--chakra-space-3);     
    `}
`;

const CustomLink = ({
  href,
  children,
  external,
  hoverBackgroundColor,
  ...props
}: {
  href: string;
  children: React.ReactNode;
  external?: boolean;
  hoverBackgroundColor?: string;
  [x: string]: any;
}) => {
  if (external) {
    return (
      <CustomLinkStyled
        href={href}
        hoverBackgroundColor={hoverBackgroundColor}
        {...props}
      >
        {children}
      </CustomLinkStyled>
    );
  }

  return (
    <Link href={href} passHref>
      <CustomLinkStyled hoverBackgroundColor={hoverBackgroundColor} {...props}>
        {children}
      </CustomLinkStyled>
    </Link>
  );
};

export default CustomLink;
