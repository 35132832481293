import { Box, Progress } from "@chakra-ui/react";
import { isValid } from "date-fns";
import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import { ReactSVG } from "react-svg";

import { OverlayContext } from "@components/legacy";
import SelectDeliveryDate from "@components/organisms/Quiz/steps/SelectDeliveryDate";
import SelectShipping from "@components/organisms/Quiz/steps/SelectShipping";
import SelectSize from "@components/organisms/Quiz/steps/SelectSize";
import SubmitButton from "@components/organisms/Quiz/SubmitButton";
import useProductFiltering from "@hooks/useProductFiltering";
import { paths } from "@paths";

import arrow from "images/arrow-left-new.svg";
import xImg from "images/x-black.svg";

const ShortQuiz: React.FC = () => {
  const overlay = useContext(OverlayContext);
  const [step, setStep] = useState<number>(0);
  const { deliveryFilter, setDeliveryFilter } = useProductFiltering();

  const DEFAULT_DATE: Date | undefined = isValid(new Date(deliveryFilter?.date))
    ? new Date(deliveryFilter?.date)
    : undefined;
  const [date, setDate] = useState<Date>(DEFAULT_DATE);
  const [selectedSizes, setSelectedSizes] = useState<Array<number>>([]);

  const router = useRouter();

  const handlePostChange = (postCode) => {
    setDeliveryFilter((_deliveryFilter) => ({
      ..._deliveryFilter,
      postCode,
    }));
  };

  const handleDateChange = (_date) => {
    setDate(_date);
    setDeliveryFilter((_deliveryFilter) => ({
      ..._deliveryFilter,
      date: new Date(_date).toISOString().substring(0, 10),
    }));
  };

  const handleNextStep = () => {
    if (step === 2) {
      router.push(
        `${paths.defaultCategory}?filters=size_${selectedSizes.join("_")}`
      );
      overlay.hide();
      return;
    }

    setStep((_step) => _step + 1);
  };

  return (
    <Box
      bgColor="white"
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      display="flex"
      flexDirection="column"
      zIndex={1100}
      width={["100%", null, null, "38rem"]}
      height={["100%", null, null, "45rem"]}
    >
      <Progress value={((step + 1) * 100) / 4} />
      <Box
        p={["1rem", null, null, "2rem"]}
        w="full"
        h="full"
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="space-between">
          {step !== 0 ? (
            <Box
              onClick={() => setStep((_step) => _step - 1)}
              cursor="pointer"
              display="flex"
              color="gray"
            >
              <Box w="0.8rem" cursor="pointer" mr="0.5rem">
                <ReactSVG src={arrow.src} />
              </Box>
              BACK
            </Box>
          ) : (
            <Box />
          )}
          <Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              cursor="pointer"
              onClick={() => overlay.hide()}
            >
              <ReactSVG src={xImg.src} />
            </Box>
          </Box>
        </Box>
        {step === 0 && (
          <SelectSize
            selectedSizes={selectedSizes}
            setSelectedSizes={setSelectedSizes}
          >
            <SubmitButton
              handleNextStep={handleNextStep}
              label={selectedSizes.length === 0 ? "SKIP" : "NEXT"}
            />
          </SelectSize>
        )}
        {step === 1 && (
          <SelectShipping
            postCode={deliveryFilter?.postCode}
            handlePostChange={handlePostChange}
          >
            <SubmitButton
              handleNextStep={handleNextStep}
              label={deliveryFilter?.postCode ? "NEXT" : "SKIP"}
            />
          </SelectShipping>
        )}
        {step === 2 && (
          <SelectDeliveryDate date={date} setDate={handleDateChange}>
            <SubmitButton
              handleNextStep={handleNextStep}
              label={date ? "NEXT" : "SKIP"}
            />
          </SelectDeliveryDate>
        )}
      </Box>
    </Box>
  );
};
export default ShortQuiz;
