import { Box } from "@chakra-ui/react";
import { useAuth } from "@the-volte/svc-core-sdk";
import Image from "next/image";
import React, { useContext } from "react";

import { Button } from "@components/atoms";
import { H1 } from "@components/atoms/Text/H1";
import { OverlayContext, OverlayType } from "@components/legacy";
import Quiz from "@components/organisms/Quiz/ShortQuiz";
import { paths } from "@paths";

import CustomLink from "../styles";

import HeroMobileLeft from "images/hero/hero_mobile_left_5.jpg";
import HeroMobileRight from "images/hero/hero_mobile_right_5.jpg";

const HeroSectionMobile: React.FC = () => {
  const overlay = useContext(OverlayContext);
  const { user } = useAuth();

  return (
    <>
      <Box
        width="100%"
        height="full"
        bgColor="#454545"
        overflow="hidden"
        position="relative"
      >
        <Image
          layout="responsive"
          src={HeroMobileRight}
          alt="hero mobile right"
          priority
        />
        <Box
          color="black"
          display="flex"
          flexDirection="row"
          w="full"
          h="full"
          position="absolute"
          top="0"
        >
          <Box
            w="full"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="flex-end"
          >
            <Box
              w="full"
              backgroundColor="#ffffff63"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="start"
              paddingTop="0.75rem"
              paddingBottom="0.75rem"
              paddingX="0.75rem"
            >
              <H1
                as="span"
                color="#000"
                fontSize="3.1rem"
                letterSpacing="unset"
                lineHeight={1}
              >
                WEAR THE DRESS
              </H1>
              <Button
                size="sm"
                fontSize="17px"
                mt="8px"
                pl="20px"
                pr="20px"
                variant="ternary"
                bgColor="#ffffff66"
                border="none"
                color="#000"
                onClick={() =>
                  overlay.toggle(OverlayType.modal, undefined, {
                    content: <Quiz />,
                  })
                }
              >
                Rent the collection &nbsp;&nbsp;⟶
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        width="100%"
        height="full"
        bgColor="#454545"
        overflow="hidden"
        position="relative"
      >
        <Image
          layout="responsive"
          src={HeroMobileLeft}
          alt="hero mobile left"
          priority
        />
        <Box
          display="flex"
          flexDirection="row"
          w="full"
          h="full"
          position="absolute"
          top="0"
        >
          <Box
            w="full"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="flex-end"
          >
            <Box
              w="full"
              backgroundColor="#ffffff63"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="start"
              paddingTop="0.75rem"
              paddingBottom="0.75rem"
              paddingX="0.75rem"
            >
              <H1
                as="span"
                color="#000"
                fontSize="3.1rem"
                letterSpacing="unset"
                lineHeight={1}
              >
                SHARE THE DRESS
              </H1>
              <CustomLink
                external
                href={
                  !user
                    ? paths.login
                    : user?.defaultShippingAddress
                    ? paths.accountLending
                    : paths.accountAddressBook
                }
                style={{
                  fontSize: "17px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginTop: "16px",
                  backgroundColor: "#ffffff66",
                  color: "#000",
                  border: "none",
                }}
                hoverBackgroundColor="#ffffff66"
              >
                Become a lender &nbsp;&nbsp;⟶
              </CustomLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HeroSectionMobile;
