import gql from "graphql-tag";

import { featuredProductsFragment } from "@graphql/fragments";

export const homePageProductsQuery = gql`
  ${featuredProductsFragment}
  query HomePageProducts($channel: String) {
    marketplaces {
      marketplace
      language
      siteUrl
    }
    shop(channel: $channel) {
      description
      name
      marketplace
      language
      domain {
        url
        host
      }
    }
    ...FeaturedProducts
    categories(level: 0, first: 4) {
      edges {
        node {
          id
          name
          slug
          backgroundImage {
            url
          }
        }
      }
    }
    collections(
      first: 15
      filter: { isFeatured: true }
      sortBy: { channel: $channel, direction: ASC, field: FEATURED_POSITION }
    ) {
      edges {
        node {
          slug
          name
          shortName
          description
          backgroundImage {
            url
            alt
          }
          backgroundDesktopImage {
            url
            alt
          }
          seoTitle
          seoDescription
        }
      }
    }
  }
`;
