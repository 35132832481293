import { GetServerSideProps } from "next";

import { channelSlug } from "@temp/constants";
import { getSaleorApi } from "@utils/ssr";

import { homePageProductsQuery, HomeView, HomeViewProps } from "../views/Home";
import {
  HomePageProducts,
  HomePageProductsVariables,
} from "../views/Home/gqlTypes/HomePageProducts";

// ts-prune-ignore-next
export default HomeView;

// ts-prune-ignore-next
export const getServerSideProps: GetServerSideProps<
  HomeViewProps
> = async () => {
  const { apolloClient } = await getSaleorApi();

  const { data } = await apolloClient.query<
    HomePageProducts,
    HomePageProductsVariables
  >({
    query: homePageProductsQuery,
    variables: { channel: channelSlug },
    fetchPolicy: "no-cache", // avoid caching of ISR pages - Apollo cache is long lasting!
  });

  return {
    // props: { data },
    props: {
      data: {
        ...data,
        collections: {
          edges: data.collections?.edges?.filter(
            (c) => c.node.slug !== "featured-products"
          ),
        },
      },
    },
  };
};
