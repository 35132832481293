import { Box } from "@chakra-ui/react";
import React from "react";
import { ReactSVG } from "react-svg";

import logoImg from "images/the_volte_logo_black.svg";

const HeroSectionTitle: React.FC = () => (
  <Box
    backgroundColor="#ffffff63"
    position="absolute"
    display="flex"
    justifyContent="center"
    width="100%"
    color="#FFF"
    left="50%"
    transform="translate(-50%, 0)"
    top="70%"
    textAlign="center"
    textTransform="uppercase"
    zIndex={10}
    height="18rem"
  >
    <Box w={[null, null, "70rem", "60rem"]}>
      <ReactSVG src={logoImg.src} />
    </Box>
  </Box>
);

export default HeroSectionTitle;
