import { Box, Flex, Grid } from "@chakra-ui/react";
import React from "react";

import { RichTextEditorContent } from "@components/atoms";
import { ProductTile } from "@components/molecules";
import {
  AsSeenIn,
  CircularPartners,
  CollectionGrid,
  FeaturedDesigners,
  SeamlessLending,
} from "@components/organisms";
import HeroSectionDesktop from "@components/organisms/Home/homepage-desktop/HeroSectionDesktop";

import { HomePageProps } from "./types";

const HomePageDesktop: React.FC<HomePageProps> = ({
  collection,
  collections,
}) => {
  const { description, products } = collection || {};
  return (
    <Flex flexDirection="column" overflow="hidden" alignItems="center">
      <HeroSectionDesktop />
      {collections && <CollectionGrid collections={collections} />}
      <Grid
        w="100%"
        templateColumns={[
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          null,
          null,
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
          "repeat(4, 1fr)",
        ]}
        gap={6}
        padding={["1.5rem 0 0.93rem 0.93rem", null, null, "3.75rem"]}
        rowGap={14}
      >
        {products?.edges?.map((product) => (
          <ProductTile product={product.node} key={product.node.id} />
        ))}
      </Grid>
      <FeaturedDesigners
        isDesktop
        title="FEATURED DESIGNERS"
        ctaText="View all designers  →"
        content="Browse collections from The Volte's favourite designers and discover hot new brands we know you will love."
      />
      <SeamlessLending />
      <CircularPartners />
      <AsSeenIn />
      <Box p="5rem 10rem" bgColor="secondary">
        <RichTextEditorContent jsonData={description} sanitizationNotRequired />
      </Box>
    </Flex>
  );
};

export default HomePageDesktop;
